angular.module('PestDiseaseApp')
    .controller('OSRSclerotiniaRiskViewController',
        function OSRSclerotiniaRiskViewController($scope,
                                                  $rootScope,
                                                  $khMessage,
                                                  $mdDialog,
                                                  $filter,
                                                  OSRSclerotiniaMonitorFactory,
                                                  model_run_slug,
                                                  location_details, GenericUtilsService) {
            $scope.monitor_name = 'OSR Sclerotinia';
            $scope.location_name = location_details.location_name;
            $scope.risk = location_details.risk;

            var AnalysisCards = [
                {
                    card_title: 'Recommendation',
                    kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/partials/includes/recommendation.html',
                    kh_card_toolbar_filters_template: '/static/assets/khapps/dss/dss_pestdisease/osr_sclerotinia/SclerotiniaToolbar.html',
                    card_flex: 100
                },
                {
                    card_title: $scope.full_plot_name,
                    kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/osr_sclerotinia/ChartConfiguration.html',
                    kh_card_toolbar_filters_template: '',
                    card_flex: 100
                },
                {
                    card_title: 'AHDB Monitor Site Information',
                    kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/osr_sclerotinia/MonitorSiteInformation.html',
                    kh_card_toolbar_filters_template: '',
                    card_flex: 100
                },
                {
                    card_title: 'Monitor Information',
                    kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/partials/MonitorInformation.html',
                    kh_card_toolbar_filters_template: '',
                    card_flex: 100
                }
            ];
            $scope.show_data = false;
            $scope.AnalysisCards = AnalysisCards;

            $scope.pestDiseaseTooltip = function () {
                return new Date(this.point.x).toDateString() + '<br>' + '<b>' + this.point.tool_tip_text + '</b>';
            };

            loadDataAndCharts();
            loadDataAndCharts();

            function loadDataAndCharts() {
                if (model_run_slug) {
                    $khMessage.show('Loading data...');

                    OSRSclerotiniaMonitorFactory.get({
                        model_run_slug: model_run_slug, location_type: location_details.location_type
                    }, function (apiData) {
                        prepareCharts(apiData);
                        $khMessage.hide();
                    }, function (error) {
                        $khMessage.show('Unable to fetch data. Please try again later or contact support team.');
                    });
                } else {
                    $khMessage.show('Please record flowering status for your location. ', 10000);
                    $scope.show_data = false;
                }

            }

            function prepareCharts(apiData) {
                $scope.OSRSclerotiniaCharts = apiData.data.chart_data;
                updateCharts(apiData.data.chart_data);
                $scope.monitor_site_information = apiData.data.monitor_site_information;
                for (var key in apiData.metadata.recommendation) {
                    if (apiData.metadata.recommendation.hasOwnProperty(key)) {
                        apiData.metadata.recommendation[key] = apiData.metadata.recommendation[key].split('\n');
                    }
                }
                $scope.recommendation = apiData.metadata.recommendation;
                $scope.monitor_description = apiData.metadata.monitor_description;
                $scope.monitor_interpretation = apiData.metadata.monitor_interpretation;
                $scope.credit = apiData.metadata.credit;
            }

            function updateCharts(chart_data) {
                $scope.risk_chart = chart_data.adjusted_risk_level_chart;
                $scope.risk_chart.tooltip = {
                    formatter: $scope.pestDiseaseTooltip
                };
                $scope.weather_chart = chart_data.sclerotinia_weather_chart;
                $scope.weather_chart.tooltip = {
                    formatter: $scope.pestDiseaseTooltip
                };
                $scope.show_data = true;
                var yAxisConfigs = chart_data.sclerotinia_weather_chart.yAxis;
                angular.forEach(yAxisConfigs, function (yAxisConfig, index) {
                    if (index === 0) {
                        yAxisConfig.labels.formatter = function () {
                            return this.value + ' mm/h';
                        };
                    }
                    else if (index === 1) {
                        yAxisConfig.labels.formatter = function () {
                            return this.value + ' °C';
                        };
                    }
                    $scope.weather_chart.yAxis[index] = yAxisConfig;
                });
            }

            function recordFloweringStatus() {
                $mdDialog.show({
                    controller: 'OSRSclerotiniaRecordFloweringStatusController',
                    skipHide: true,
                    multiple: true,
                    templateUrl: '/static/assets/khapps/dss/dss_pestdisease/osr_sclerotinia/record_flowering_status_dialog.html',
                    locals: {
                        flowering_status_response: {
                            location_name: location_details.location_name,
                            latitude: location_details.latitude,
                            longitude: location_details.longitude,
                            region: null,
                            growing_season: $rootScope.selected_growing_season.value
                        }
                    }
                }).then(function (flowering_data) {
                    $khMessage.show('Running sclerotinia model with updated flowering status...', 10000);
                    OSRSclerotiniaMonitorFactory.create({
                        'location_type': location_details.location_type,
                        'location_slug': location_details.location_slug,
                        'growing_season': $rootScope.selected_growing_season.value,
                        'flowering_data': flowering_data
                    }, function (response) {
                        location_details.run_date = response.data.model_run_datetime;
                        location_details.model_run_slug = response.data.model_run_slug;
                        location_details.risk = response.data.run_day_risk;
                        $scope.risk = response.data.run_day_risk;
                        location_details.run_status = 'Success';
                        $rootScope.$broadcast('updatedSclerotiniaModelRun', location_details);
                        prepareCharts(response);

                         OSRSclerotiniaMonitorFactory.get({
                        model_run_slug: response.data.model_run_slug, location_type: location_details.location_type
                    }, function (apiData) {
                        prepareCharts(apiData);
                        $khMessage.hide();
                    }, function (error) {
                        $khMessage.show('Unable to fetch data. Please try again later or contact support team.');
                    });

                        $khMessage.hide();
                    }, function (error) {
                        if (error.status == 400) {
                            $khMessage.show(error.data.errors[0]);
                        } else {
                            GenericUtilsService.handleErrorV2($filter, $khMessage, error);
                        }

                    });
                });
            }

            $scope.recordFloweringStatus = recordFloweringStatus;
        }
    );
