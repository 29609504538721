angular.module("DssIrrigationApp").service('DSSIrrigationChartConfigFactory', [function () {

    var getTickPositions = function () {
        var maxDeviation = Math.ceil(Math.max(Math.abs(this.dataMax), Math.abs(this.dataMin)));
        var halfMaxDeviation = Math.ceil(maxDeviation / 2);
        var oneAndAHalfMaxDeviation = Math.ceil(maxDeviation * 1.5);
        return [-oneAndAHalfMaxDeviation, -maxDeviation, -halfMaxDeviation, 0, halfMaxDeviation, maxDeviation, oneAndAHalfMaxDeviation];
    };

    this.getChartConfig = function (param_dict) {
        return {
            exporting: {
                enabled: false
            },
            lang: {
                noData: 'Loading Data...'
            },
            chart: {
                type: 'spline',
                zoomType: 'xy',
                plotBorderWidth: 2
            },
            tooltip: {
                shared: true
            },
            title: {
                text: ''
            },
            legend: {
                title: {
                    text: 'Click to toggle items on/off',
                    style: {
                        fontStyle: 'italic',
                        fontWeight: 'normal',
                        color: '#7e7e7e'
                    }
                },
                align: 'center',
                verticalAlign: 'top',
                layout: 'horizontal',
                borderWidth: 0,
                shadow: false,
                margin: 40,
                itemMarginTop: 10
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'datetime',
                plotLines: [{
                    label: {text: 'Now', rotation: -2, y: 30},
                    color: 'black',
                    value: (new Date()).getTime(),
                    width: 2
                }]
            },
            yAxis: [
                {
                    labels: {
                        formatter: function () {
                            return this.value + ' cm';
                        },
                        style: {
                            color: '#F16C20'
                        }
                    },
                    title: {
                        text: 'Root Depth',
                        style: {
                            color: '#F16C20',
                            fontWeight: 'bold'
                        }
                    },
                    reversed: false,
                    opposite: false,
                    plotLines: [{
                        color: 'grey',
                        value: 0,
                        width: 1
                    }],
                    tickPositioner: getTickPositions,
                    gridLineWidth: 0
                }, {
                    labels: {
                        formatter: function () {
                            return this.value + ' cm';
                        },
                        style: {
                            color: '#0D3C55'
                        }
                    },
                    title: {
                        text: 'Irrigation Depth',
                        style: {
                            color: '#0D3C55',
                            fontWeight: 'bold'
                        }
                    },
                    plotLines: [{
                        color: 'grey',
                        value: 0,
                        width: 1
                    }],
                    tickPositioner: getTickPositions,
                    gridLineWidth: 0,
                    opposite: false
                }, {
                    labels: {
                        formatter: function () {
                            return this.value + ' cm';
                        },
                        style: {
                            color: '#E5C342'
                        }
                    },
                    title: {
                        text: 'Soil Moisture Deficit',
                        style: {
                            color: '#E5C342',
                            fontWeight: 'bold'
                        }
                    },
                    plotLines: [{
                        color: 'grey',
                        value: 0,
                        width: 1
                    }],
                    opposite: false,
                    tickPositioner: getTickPositions,
                    gridLineWidth: 0
                }, {
                    labels: {
                        formatter: function () {
                            return this.value + ' cm';
                        },
                        style: {
                            color: '#A1B86C'
                        }
                    },
                    title: {
                        text: 'Water Requirement',
                        style: {
                            color: '#A1B86C',
                            fontWeight: 'bold'
                        }
                    },
                    plotLines: [{
                        color: 'grey',
                        value: 0,
                        width: 1
                    }],
                    tickPositioner: getTickPositions,
                    gridLineWidth: 0,
                    opposite: true
                }, {
                    labels: {
                        formatter: function () {
                            return this.value + ' cm';
                        },
                        style: {
                            color: '#1395BA'
                        }
                    },
                    title: {
                        text: 'Effective Rainfall',
                        style: {
                            color: '#1395BA',
                            fontWeight: 'bold'
                        }
                    },
                    reversed: false,
                    plotLines: [{
                        color: 'grey',
                        value: 0,
                        width: 1
                    }],
                    tickPositioner: getTickPositions,
                    gridLineWidth: 0,
                    opposite: true

                }, {
                    labels: {
                        formatter: function () {
                            return this.value;
                        },
                        style: {
                            color: '#EF8B2C'
                        }
                    },
                    title: {
                        text: 'Crop Coefficient',
                        style: {
                            color: '#EF8B2C',
                            fontWeight: 'bold'
                        }
                    },
                    plotLines: [{
                        color: 'grey',
                        value: 0,
                        width: 1
                    }],
                    opposite: true,
                    tickPositioner: getTickPositions,
                    gridLineWidth: 0
                }

            ],
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            series: [
                {
                    name: 'Rainfall',
                    color: '#1395BA',
                    type: 'column',
                    yAxis: 4,
                    data: param_dict.eff_rain,
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        valueSuffix: ' cm',
                        valueDecimals: 2
                    }
                },
                {
                    name: 'Irrigation Depth',
                    color: '#0D3C55',
                    type: 'column',
                    yAxis: 1,
                    data: param_dict.irrigation,
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        valueSuffix: ' cm',
                        valueDecimals: 2
                    }
                },
                {
                    name: 'Water Requirement',
                    color: '#A1B86C',
                    type: 'spline',
                    yAxis: 3,
                    data: param_dict.act_et,
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        valueSuffix: ' cm',
                        valueDecimals: 2
                    }
                },
                {
                    name: 'Root Depth',
                    color: '#F16C20',
                    type: 'spline',
                    yAxis: 0,
                    data: param_dict.root_depth,
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        pointFormatter: function () {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + Math.abs(this.y.toFixed(1)) + ' cm</b><br/>';
                        }
                    },
                    visible: false
                },
                {
                    name: 'Soil Moisture Deficit',
                    color: '#E5C342',
                    type: 'spline',
                    yAxis: 2,
                    data: param_dict.smd,
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        pointFormatter: function () {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + Math.abs(this.y.toFixed(1)) + ' cm</b><br/>';
                        }
                    }
                },
                {
                    name: 'Soil Moisture Deficit Threshold',
                    color: '#C02E1C',
                    type: 'spline',
                    yAxis: 2,
                    data: param_dict.smd_threshold,
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        pointFormatter: function () {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + Math.abs(this.y.toFixed(1)) + ' cm</b><br/>';
                        }
                    }
                },
                {
                    name: 'Soil Moisture Deficit Target',
                    color: '#5CA793',
                    type: 'spline',
                    yAxis: 2,
                    data: param_dict.smd_target,
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        pointFormatter: function () {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + Math.abs(this.y.toFixed(1)) + ' cm</b><br/>';
                        }
                    },
                    visible: false
                },
                {
                    name: 'Recommended Irrigation',
                    color: '#ECA938',
                    type: 'column',
                    yAxis: 1,
                    data: param_dict.recommended_irrigation,
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        valueSuffix: ' cm',
                        valueDecimals: 1
                    }
                },
                {
                    name: 'Crop Coefficient',
                    color: '#EF8B2C',
                    type: 'spline',
                    yAxis: 5,
                    data: param_dict.crop_coeff,
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        valueSuffix: '',
                        valueDecimals: 1
                    },
                    visible: false
                }
            ]

        };
    };
}]
);