(function (angular) {
    "use strict";
    angular.module('PestDiseaseApp')
        .controller('WheatFusariumMonitorController', function WheatFusariumMonitorController($rootScope,
                                                                                              $scope,
                                                                                              $filter,
                                                                                              $khMessage,
                                                                                              $mdDialog,
                                                                                              NgTableParams,
                                                                                              FusariumOutputFactory,
                                                                                              DateTimeHelperFactory,
                                                                                              DSSUtilsService, GenericUtilsService) {

            $rootScope.currentNavItem = "WheatFusarium";
            $rootScope.page_id = "decisionsupport";
            $rootScope.sub_app_page_id = "decisionsupport_pestdisease";
            $rootScope.tenant_id = tenant_id;


            function getFusariumMonitorSummary(pageNo) {
                var querystring = {
                    farm: "All Farms",
                    field: "All Fields",
                    season: $rootScope.selected_growing_season.value,
                    team: $rootScope.selected_team.value,
                    page: pageNo
                };
                FusariumOutputFactory.query(querystring, function (response) {
                    $scope.fusarium_info_filepath = response.metadata.fusarium_info_filepath;
                    $scope.model_is_active = response.metadata.model_is_active;
                    $scope.monitor_output_list = $scope.monitor_output_list.concat(response.data);
                    angular.forEach($scope.monitor_output_list, function (output) {
                        if (output.run_date) {
                            output.readable_run_date = DateTimeHelperFactory.convertDateTimeFormatUsingMoment(output.run_date, "YYYY-MM-DD HH:mm");
                        }
                        if (output.date_of_ear_emergence) {
                            output.readable_expected_date_of_ear_emergence = DateTimeHelperFactory.convertDateFormatUsingMoment(output.date_of_ear_emergence, "YYYY-MM-DD");
                        }
                        if (typeof(output.growing_season) === 'undefined') {
                            output['growing_season'] = $rootScope.selected_growing_season.value;
                        }
                    });
                    if (response.next) {
                        getFusariumMonitorSummary(response.current_page + 1);
                    }
                    $scope.monitorSummaryTable = new NgTableParams({}, {dataset: $scope.monitor_output_list});
                    $scope.showEmptyState = ($scope.monitor_output_list.length === 0);
                }, function (error) {
                    $scope.monitorSummaryTable = new NgTableParams({}, {dataset: $scope.monitor_output_list});
                    $scope.showEmptyState = ($scope.monitor_output_list.length === 0);
                    GenericUtilsService.handleErrorV2($filter, $khMessage, error);
                })
            }

            $scope.$on("updatedFusariumModelRun", function (boradcast, data) {
                $scope.monitor_output_list = GenericUtilsService.updateListAfterEdit($scope.monitor_output_list, "location_slug", data.location_slug, data);
                $scope.monitorSummaryTable = new NgTableParams({}, {dataset: $scope.monitor_output_list});
            });

            $scope.$on("filter:selections_updated",
                function () {
                    $scope.monitor_output_list = [];
                    getFusariumMonitorSummary(1);
                }
            );

            $scope.$on('$routeChangeSuccess', function (scope, next, previous) {
                if (previous) {
                    $scope.monitor_output_list = [];
                    getFusariumMonitorSummary(1);
                }
            });

            $scope.redirecttoMyFarm = function () {
                window.open('/dashboard/farm/#/', '_blank');
            };

            $scope.AnalysisCards = [
                {
                    card_title: $filter('translate')('cropPestDisease.information'),
                    kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/partials/fusarium/FusariumInformation.html',
                    card_flex: 100
                },
                {
                    card_title: $filter('translate')('cropPestDisease.fusarium_risk'),
                    kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/partials/fusarium/FusariumOutputSummaryTable.html',
                    kh_card_toolbar_filters_template: '/static/assets/khapps/dss/dss_pestdisease/partials/fusarium/FusariumSummaryTableToolbar.html',
                    card_flex: 100,
                    show_progress_bar: true
                }
            ];

            $scope.viewMonitor = function (monitor_output) {
                $mdDialog.show({
                    templateUrl: '/static/assets/khapps/dss/dss_pestdisease/partials/fusarium/fusarium_output_dialog.html',
                    controller: 'WheatFusariumOutputController',
                    locals: {
                        monitor_output: monitor_output,
                        model_is_active: $scope.model_is_active
                    }
                })
            };

            $scope.monitorSummaryFilters = {
                location: DSSUtilsService.getTextFilterDict($filter, 'location_short_name', 'cropPestDisease.filter_by_location'),
                location_type: DSSUtilsService.getTextFilterDict($filter, 'location_type', 'cropPestDisease.filter_by_location_type'),
                previous_crop: DSSUtilsService.getTextFilterDict($filter, 'previous_crop', 'cropPestDisease.filter_by_previous_crop'),
                cultivation_method: DSSUtilsService.getTextFilterDict($filter, 'cultivation_method', 'cropPestDisease.filter_by_cultivation_method'),
                date_of_ear_emergence: DSSUtilsService.getTextFilterDict($filter, 'readable_expected_date_of_ear_emergence', 'cropPestDisease.filter_by_date_of_ear_emergence'),
                risk: DSSUtilsService.getTextFilterDict($filter, 'risk', 'cropPestDisease.filter_by_risk'),
                run_on: DSSUtilsService.getTextFilterDict($filter, 'readable_run_date', 'cropPestDisease.filter_by_last_run'),
                run_status: DSSUtilsService.getTextFilterDict($filter, 'run_status', 'cropPestDisease.filter_by_run_status')
            };

        });
})(angular);
