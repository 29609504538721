angular.module('PestDiseaseApp')
    .controller('RerunFusariumController',
        function RerunFusariumController($rootScope,
                                         $scope,
                                         $filter,
                                         $khMessage,
                                         $mdDialog,
                                         FusariumOutputFactory,
                                         MonitorInputFactory,
                                         monitor_output,
                                         monitorInput, GenericUtilsService) {

            $scope.monitorOutput = monitor_output;
            $scope.dialogTitle = 'Record parameters';

            var monitorInputForm = function () {
                return [
                    {
                        className: 'flex-100',
                        type: 'searchable_select',
                        key: 'PREVIOUS_CROP',
                        templateOptions: {
                            label: 'Previous crop',
                            required: true,
                            options: []
                        },
                        controller: function ($scope, FusariumConstantsFactory) {
                            FusariumConstantsFactory.get({input_key: 'PREVIOUS_CROP'}, function (response) {
                                $scope.to.options = response.data;
                            });
                        }
                    }, {
                        className: 'flex-100',
                        type: 'searchable_select',
                        key: 'CULTIVATION_METHOD',
                        templateOptions: {
                            label: 'Cultivation method',
                            required: true,
                            options: []
                        },
                        controller: function ($scope, FusariumConstantsFactory) {
                            FusariumConstantsFactory.get({input_key: 'CULTIVATION_METHOD'}, function (response) {
                                $scope.to.options = response.data;
                            });
                        }
                    },
                    {
                        className: 'flex-100',
                        type: 'datepicker',
                        key: 'DATE_OF_EAR_EMERGENCE',
                        templateOptions: {
                            label: 'Expected date of ear emergence (GS59-61)',
                            required: true
                        }
                    }
                ];
            };

            $scope.monitorInputForm = monitorInputForm();

            $scope.monitorInput = monitorInput;

            $scope.sendData = function () {
                var input_keys = ['DATE_OF_EAR_EMERGENCE', 'PREVIOUS_CROP', 'CULTIVATION_METHOD'];
                angular.forEach(input_keys, function (input_key) {
                    var data_dict = {
                        growing_season: $scope.monitorInput.growing_season,
                        object_slug: $scope.monitorInput.object_slug,
                        object_type: $scope.monitorInput.object_type,
                        input_key: input_key,
                        input_value: $scope.monitorInput[input_key]
                    };
                    if (data_dict.input_key === 'DATE_OF_EAR_EMERGENCE') {
                        data_dict.input_value = moment(data_dict.input_value).format('YYYY-MM-DD');
                    }
                    MonitorInputFactory.save(data_dict, function (response) {
                        if (input_key === 'CULTIVATION_METHOD') {
                            $scope.rerunModel();
                        }
                    }, function (error) {
                        GenericUtilsService.handleErrorV2($filter, $khMessage, error);
                        $mdDialog.hide(error);
                    });
                });
            };

            $scope.rerunModel = function () {
                var data = {
                    location_slug: monitor_output.location_slug,
                    location_type: monitor_output.location_type,
                    growing_season: monitor_output.growing_season
                };
                FusariumOutputFactory.create(data, function (response) {
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleErrorV2($filter, $khMessage, error);
                });
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };

        }
    );
