(function (angular) {
    "use strict";
    angular.module('DssSetupApp')
        .controller('DssAddButtonController', [
            "$rootScope",
            "$scope",
            "$mdDialog",
            DssAddButtonController
        ]);

    function DssAddButtonController($rootScope,
                                    $scope,
                                    $mdDialog) {
        $scope.showDssAppModal = function (item) {
            switch (item) {
                case 'cropmodel':
                    $mdDialog.show({
                        templateUrl: '/static/assets/khapps/dss/setup/crop_model_dialog_form.html',
                        controller: 'AddEditCropModelController',
                        scope: $scope,
                        preserveScope: true,
                        locals: {
                            slug: null
                        }
                    }).then(function (result) {
                        $rootScope.$broadcast('addedCropModel', result);
                    });
                    break;
                default:
                    alert("Unknown type");
            }
        };
    }
})(angular);
