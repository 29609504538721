angular
  .module('DssMainApp')
  .controller('dssMainAppController', function(
    $scope,
    $rootScope,
    $mdDialog,
    NgTableParams,
    leafletData,
    DssMainApplicationsDataService,
    GenericMapService
  ) {
    var DSS_APPLICATION_LIST = [
      { name: 'Crop Irrigation', value: 'cropirrigation' },
      { name: 'Crop Growth', value: 'cropgrowth' },
      { name: 'Pest and Disease', value: 'pestdisease' },
      { name: 'Yield', value: 'cropyield' }
    ];

    var DSS_VIEW_CONTAINER = {
      pageTitle: 'Decision Support',
      pageId: 'decisionsupport',
      navbarLinks: [
        { name: 'Irrigation Monitor', action: 'addCroptoIrrigationMonitor' },
        { name: 'Pest Monitor', action: 'addCroptoPestMonitor' }
      ],
      navbarTabs: DSS_APPLICATION_LIST,
      cards: [
        {
          card_title: 'Protocols',
          kh_card_content_template:
            '/static/assets/khapps/dss/dss_main/partials/TableView.html',
          kh_card_toolbar_filters_template: '',
          card_flex: 100
        },
        {
          card_title: 'Protocols',
          kh_card_content_template:
            '/static/assets/khapps/dss/dss_main/partials/TableView.html',
          kh_card_toolbar_filters_template: '',
          card_flex: 100
        }
      ]
    };

    $rootScope.navbarLinks = DSS_VIEW_CONTAINER.navbarLinks;
    $scope.containerViewCard = DSS_VIEW_CONTAINER.cards;

    $rootScope.pageTitle = DSS_VIEW_CONTAINER.pageTitle;
    $rootScope.page_id = DSS_VIEW_CONTAINER.pageId;

    $rootScope.showViews = function() {
      return true;
    };
    $rootScope.showFilters = function() {
      return false;
    };

    $rootScope.callFunction = function(name) {
      if (angular.isFunction($scope[name])) $scope[name]();
    };

    $rootScope.availableParams = [
      { name: 'Parameter 1', value: 'Parameter1' },
      {
        name: 'Parameter 2',
        value: 'Parameter2'
      },
      { name: 'Parameter 3', value: 'Parameter3' }
    ];

    $scope.dataApps = DssMainApplicationsDataService.getIrrigationFieldPlotCropData();
    $scope.dataAppsTableParameters = new NgTableParams(
      {},
      { dataset: $scope.dataApps }
    );
    $scope.selectedApp = $scope.dataApps[0];

    $scope.changeDataView = function(selectedDataView) {
      $scope.currentDataView = selectedDataView;
    };

    var layer;
    layer = GenericMapService.buildmap($scope, $rootScope, leafletData, mapid);

    $scope.toggleSideBar = function() {
      $scope.sidebar.toggle();
    };

    $rootScope.$watch('selectedFeature', function(newValue, oldValue) {
      if (newValue) {
        $scope.sidebar.show();
        $scope.showEdit = false;
        $scope.feature = newValue.feature;
        switch (newValue.options.layer) {
          case 'FieldLayer':
            $scope.sidebar_path =
              '/static/assets/khapps/fms_app/myfarm/map/sidebar/field/field-sidebar.directive.html';
            break;
          case 'PlotLayer':
            $scope.sidebar_path =
              '/static/assets/khapps/fms_app/myfarm/map/sidebar/plot/plot-sidebar.directive.html';
            break;
          default:
            if (
              newValue.feature.properties.hasOwnProperty('soil_sample_slug')
            ) {
              $scope.sidebar_path =
                '/static/assets/khapps/fms_app/myfarm/map/sidebar/soil-sample/soil-sample-sidebar.directive.html';
            } else if (
              newValue.feature.properties.hasOwnProperty('store_slug')
            ) {
              $scope.showEdit = true;
              $scope.sidebar_path =
                '/static/assets/khapps/fms_app/myfarm/map/sidebar/store/store-sidebar.directive.html';
            } else {
              $scope.sidebar_path =
                '/static/assets/khapps/fms_app/myfarm/map/sidebar/farm/farm-sidebar.directive.html';
            }
            break;
        }
      }
    });
  });
