(function(angular){
    'use strict';
    angular.module('PestDiseaseApp')
        .factory('OSRSclerotiniaMonitorFactory', OSRSclerotiniaMonitorFactory);

    function OSRSclerotiniaMonitorFactory($resource){
        return $resource('/api/v2.0/monitor/sclerotinia/:model_run_slug/', {
            model_run_slug: '@model_run_slug',
            location_type: '@location_type'
        },
        {
            query: {
                method: 'GET',
                is_array: false
            },
            get: {
                method: 'GET'
            },
            create: {
                method: 'POST'
            }
        }
        );
    }

})(angular);