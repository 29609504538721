(function(angular){
    "use strict";
    angular.module('PestDiseaseApp')
        .factory('FusariumOutputFactory', FusariumOutputFactory);

    function FusariumOutputFactory($resource){
        return $resource('api/v2.0/monitor/fusarium/:model_run_slug/', {}, {
            query: {
                isArray: false
            },
            get: {
                method: 'GET'
            },
            create: {
                method: 'POST'
            }
        });
    }

})(angular);