angular.module('PestDiseaseApp').controller('OSRSclerotiniaMonitorController',
    function ($scope, $rootScope, $khMessage, $filter, $mdDialog, NgTableParams,
              OSRSclerotiniaMonitorFactory, FarmSummaryFactory, DateTimeHelperFactory,
              DSSUtilsService, GenericUtilsService) {

        $rootScope.currentNavItem = 'OSRSclerotinia';
        DSSUtilsService.appSideBar($rootScope, 'decisionsupport', 'decisionsupport_pestdisease');

        $scope.tableFilters = {
            team_name: DSSUtilsService.getGenericFilterDict($filter, 'team_name', 'text', 'cropPestDisease.filter_by_team'),
            location_name: DSSUtilsService.getGenericFilterDict($filter, 'location_name', 'text', 'cropPestDisease.filter_by_location'),
            location_type: DSSUtilsService.getGenericFilterDict($filter, 'location_type', 'text', 'cropPestDisease.filter_by_location_type'),
            variety_name: DSSUtilsService.getGenericFilterDict($filter, 'variety', 'text', 'cropPestDisease.filter_by_variety'),
            risk: DSSUtilsService.getGenericFilterDict($filter, 'risk', 'text', 'cropPestDisease.filter_by_risk'),
            run_date: DSSUtilsService.getGenericFilterDict($filter, 'readable_run_date', 'text', 'cropPestDisease.filter_by_last_run '),
            run_status: DSSUtilsService.getGenericFilterDict($filter, 'run_status', 'text', 'cropPestDisease.filter_by_run_status')
        };

        $rootScope.$on('team_changed', function (obj, slug) {
            $khMessage.show('Please wait. Updating data.');
            setUpMonitorData();
        });

        $scope.$on('filter:selections_updated', function (obj, filterValue) {
            if (filterValue.hasOwnProperty('growing_season')) {
                setUpMonitorData();
            }
        });

        $scope.$on('$routeChangeSuccess', function (scope, next, previous) {
            if (previous) setUpMonitorData();
        });

        $scope.AnalysisCards = [
            {
                card_title: 'Information',
                kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/osr_sclerotinia/Information.html',
                kh_card_toolbar_filters_template: '',
                card_flex: 100
            },
            {
                card_title: 'Sclerotinia Risk',
                kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/osr_sclerotinia/OSRSclerotiniaSummaryTable.html',
                kh_card_toolbar_filters_template: '',
                card_flex: 100,
                show_progress_bar: true
            }
        ];

        function setUpMonitorData() {
            if (angular.isUndefined(typeof($rootScope.selected_growing_season))) {
                $rootScope.selected_growing_season = {};
                $rootScope.selected_growing_season.name = $scope.planting_season_current;
                $rootScope.selected_growing_season.value = $scope.planting_season_current;
            }

            $scope.sclerotinia_list = [];
            load_sclerotinia_data(1);
        }

        function load_sclerotinia_data(pageNo) {
            OSRSclerotiniaMonitorFactory.query({
                farm: 'All Farms',
                field: 'All Fields',
                season: $rootScope.selected_growing_season.value,
                team: $rootScope.selected_team,
                page: pageNo
            }, function (result) {
                $scope.sclerotinia_list = $scope.sclerotinia_list.concat(result.data);
                formatDataAndBuildTable();
                if (result.next) {
                    load_sclerotinia_data(result.current_page + 1);
                }

            });
        }

        function formatDataAndBuildTable() {
            angular.forEach($scope.sclerotinia_list, function (output) {
                if (!output.readable_run_date) {
                    if (output.run_date) {
                        output.readable_run_date = DateTimeHelperFactory.convertDateTimeFormatUsingMoment(output.run_date, 'YYYY-MM-DD HH:mm');
                    }
                }
            });
            $scope.tableParams = new NgTableParams({}, {dataset: $scope.sclerotinia_list});
            $scope.showEmptyState = ($scope.sclerotinia_list.length === 0);
        }

        $scope.redirecttoMyFarm = function () {
            window.open('/dashboard/farm/#/', '_blank');
        };

        $scope.viewSclerotiniaDetail = function (output) {
            $mdDialog.show({
                controller: 'OSRSclerotiniaRiskViewController',
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/dss/dss_pestdisease/osr_sclerotinia/Sclerotinia_RiskView.html',
                locals: {
                    model_run_slug: output.model_run_slug,
                    location_details: output
                }
            });
        };

        $scope.$on('updatedSclerotiniaModelRun', function (boradcast, response) {
            response.readable_run_date = DateTimeHelperFactory.convertDateTimeFormatUsingMoment(response.run_date, 'YYYY-MM-DD HH:mm');
            $scope.sclerotinia_list = GenericUtilsService.updateListAfterEdit($scope.sclerotinia_list, 'location_slug', response.location_slug, response);
            formatDataAndBuildTable();
        });

    });