angular.module('DssMainApp').service("DSSUtilsService", function () {
    this.initController = function ($scope, $rootScope, $filter) {
        $rootScope.callFunction = function (name) {
            if (angular.isFunction($scope[name]))
                $scope[name]();
        };
        $rootScope.getSort = function (sort_text) {
            return $filter('translate')('common.sortBy') + sort_text
        };
        $rootScope.getColumnFullName = function (sort_text) {
            return $filter('translate')(sort_text)
        };
    };

    this.appSideBar =  function ($rootScope, app_pageid, subapp_pageid) {
        $rootScope.page_id = app_pageid;
        if (subapp_pageid) $rootScope.sub_app_page_id = subapp_pageid;
        else delete $rootScope.sub_app_page_id;
    };

    this.updateObjectInList = function (object_list, key_to_match, updated_object, fn) {
        for (var i = 0; i < object_list.length; i++) {
            if (object_list[i][key_to_match] == updated_object[key_to_match]) {
                object_list[i] = updated_object;
                if (fn) fn();
                return true;
            }
        }
    };

    this.deleteObjectFromList = function (object_list, key_to_match, deleted_object, fn) {
        for (var i = 0; i < object_list.length; i++) {
            if (object_list[i][key_to_match] == deleted_object[key_to_match]) {
                object_list.splice(i, 1);
                if (fn) fn();
                return true;
            }
        }
    };

    this.getUnitId = function (units, unit_name) {
        for (var i = 0; i < units.length; i++) {
            if (units[i].name == unit_name) {
                return units[i].value;
            }
        }
    };

    this.getPlotName = function (plots, field_plot_crop_slug) {
        for (var i = 0; i < plots.length; i++) {
            if (field_plot_crop_slug == plots[i].value) {
                return plots[i].name;
            }
        }
    };

    this.getFilterDict =  function ($filter, key, site_code) {
        var dict = {};
        dict[site_code] = {
            id: 'text',
            placeholder: $filter('translate')('common.filterBy') + $filter('translate')(key + '.' + site_code + '').toLowerCase()
        };
        return dict;
    };


    this.getTextFilterDict =  function ($filter, key, placeholder_key) {
        var dict = {};
        dict[key] = {
            id: 'text',
            placeholder: $filter('translate')(placeholder_key)
        };
        return dict;
    };

    this.getGenericFilterDict = function ($filter, key, type, placeholder) {
        var dict = {};
        dict[key] = {
            id: type,
            placeholder: $filter('translate')(placeholder)
        };
        return dict;
    };

    this.convertFusariumDatesIntoReadable =  function (DateTimeHelperFactory, data) {
        if (data.run_date) {
            data.readable_run_date = DateTimeHelperFactory.convertDateTimeFormatUsingMoment(data.run_date, "YYYY-MM-DD HH:mm");
        }
        if (data.date_of_ear_emergence) {
            data.readable_expected_date_of_ear_emergence = DateTimeHelperFactory.convertDateFormatUsingMoment(data.date_of_ear_emergence, "YYYY-MM-DD");
        }
        return data;
    };
});

