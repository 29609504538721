/**
    Component version of the field inventory table
*/
angular.module('KisanHubDashboardApp').component('dashboard', {
    templateUrl: '/static/assets/khapps/dashboard/dashboard.component.html',
    controller: function(
        $rootScope,
        $mdDialog,
        PhoenixService
    ) {
        var self = this;

        this.tenant = tenant_id;
        $rootScope.pageTitle = 'Dashboard';
        $rootScope.page_id = 'dashboard';

        self.showArticle = function(article) {
            callArticleAgain(article.slug);
        };

        self.phoenixHome = () => {
            PhoenixService.getRefreshToken().then( (token)=> {
                window.location = `${PhoenixService.getHostUrl}?token=${token}`;
            });
        }

        function callArticleAgain(article_slug) {
            $mdDialog
                .show({
                    controller: 'SingleArticleSummaryController',
                    templateUrl:
                        '/static/assets/khapps/articles/articles/partials/SingleArticle.html',
                    parent: angular.element(document.body),
                    locals: {
                        article_slug: article_slug
                    },
                    clickOutsideToClose: false
                })
                .then(function(some) {
                    if (some) {
                        callArticleAgain(some);
                    }
                });
        }


    }
});
