import { downgradeComponent } from '@angular/upgrade/static';
import { DashboardComponent } from '../../angular/src/app/dashboard/dashboard.component';
import { ArticlesSummaryComponent } from '../../angular/src/app/dashboard/articles-summary/articles-summary/articles-summary.component';

require('angulartics');

export default angular.module('KisanHubDashboardApp', [
  'angulartics',
  require('angulartics-google-analytics'),
  'KhBase',
  'highcharts-ng',
  'FiltersData',
  'ArticlesSummary',
  'ApiUtils.khutils.ui'
]);

angular
  .module('KisanHubDashboardApp')
  .directive(
    'appDashboard',
    downgradeComponent({ component: DashboardComponent })
  )
  .directive(
    'appArticlesSummary',
    downgradeComponent({
      component: ArticlesSummaryComponent,
      outputs: ['showArticle']
    })
  );
