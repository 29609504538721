angular.module("DssSetupApp").run(["$http", "$rootScope", "formlyConfig", "formlyValidationMessages",
    function ($http, $rootScope, formlyConfig, formlyValidationMessages) {
        $http.defaults.xsrfCookieName = "csrftoken";
        $http.defaults.xsrfHeaderName = "X-CSRFToken";

        formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = 'fc.$touched || form.$submitted';
        formlyValidationMessages.addStringMessage('required', 'This field is required');

        $rootScope.in_app_links_template = '';
        $rootScope.view_as_template = "";
        $rootScope.select_filters_template = "";

        $rootScope.tableview = true;
        $rootScope.mapview = false;
        $rootScope.cardview = false;
    }]);