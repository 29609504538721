angular.module('PestDiseaseApp')
    .controller('MonitorSummaryController', function MonitorSummaryController($rootScope,
                                                                              $scope,
                                                                              $filter,
                                                                              $khMessage,
                                                                              $mdDialog,
                                                                              NgTableParams,
                                                                              MonitorSummaryFactory,
                                                                              MonitorOutputFactory,
                                                                              DateTimeHelperFactory,
                                                                              DSSUtilsService, GenericUtilsService) {

        $rootScope.currentNavItem = "Powdery Mildew";
        $rootScope.page_id = "decisionsupport";
        $rootScope.sub_app_page_id = "decisionsupport_pestdisease";
        $rootScope.tenant_id = tenant_id;
        var ALL_SEASONS = 'All Seasons';

        function getMonitorSummary() {
            if (angular.isUndefined($rootScope.selected_growing_season)) {
                $rootScope.selected_growing_season = {};
                $rootScope.selected_growing_season.name = $scope.planting_season_current;
                $rootScope.selected_growing_season.value = $scope.planting_season_current;
            }
            if ($rootScope.selected_growing_season.value === ALL_SEASONS) {
                $khMessage.show($filter('translate')('please_choose_a_growing_season'));
            } else {
                var querystring = {
                    growing_season: $rootScope.selected_growing_season.value,
                    team: $rootScope.selected_team
                };
                MonitorSummaryFactory.query(querystring, function (response) {
                    angular.forEach(response, function (datum) {
                        if (datum.run_date) {
                            datum.readable_run_date = DateTimeHelperFactory.convertDateTimeFormatUsingMoment(datum.run_date, "YYYY-MM-DD HH:mm");
                        }
                    });
                    $scope.monitor_output_list = $scope.monitor_output_list.concat(response);
                    $scope.monitorSummaryTable = new NgTableParams({}, {dataset: $scope.monitor_output_list});
                    $scope.showEmptyState = ($scope.monitor_output_list.length === 0);
                }, function (error) {
                    $scope.monitorSummaryTable = new NgTableParams({}, {dataset: $scope.monitor_output_list});
                    $scope.showEmptyState = ($scope.monitor_output_list.length === 0);
                    GenericUtilsService.handleErrorV2($filter, $khMessage, error);
                })
            }
        }

        $scope.$on("filter:selections_updated",
            function (broadcast, selections) {
                if (selections.growing_season) {
                    $scope.monitor_output_list = [];
                    getMonitorSummary();
                }
            }
        );

        $scope.$on('$routeChangeSuccess', function (scope, next, previous) {
            if (previous) {
                $scope.monitor_output_list = [];
                getMonitorSummary();
            }
        });

        $scope.AnalysisCards = [
            {
                card_title: $filter('translate')('cropPestDisease.pest_disease_risk'),
                kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/partials/MonitorOutputSummaryTable.html',
                card_flex: 100,
                show_progress_bar: true
            }
        ];

        $scope.viewMonitor = function (monitor_output) {
            $mdDialog.show({
                templateUrl: '/static/assets/khapps/dss/dss_pestdisease/partials/pest_disease_chart_dialog.html',
                controller: 'PestDiseaseChartController',
                locals: {
                    monitor_output: monitor_output
                }
            })
        };

        $scope.monitorSummaryFilters = {
            team: DSSUtilsService.getTextFilterDict($filter, 'team', 'cropPestDisease.filter_by_team'),
            farm: DSSUtilsService.getTextFilterDict($filter, 'farm', 'cropPestDisease.filter_by_farm'),
            field: DSSUtilsService.getTextFilterDict($filter, 'field', 'cropPestDisease.filter_by_field'),
            plot: DSSUtilsService.getTextFilterDict($filter, 'plot', 'cropPestDisease.filter_by_plot'),
            crop: DSSUtilsService.getTextFilterDict($filter, 'crop', 'cropPestDisease.filter_by_crop'),
            variety: DSSUtilsService.getTextFilterDict($filter, 'variety', 'cropPestDisease.filter_by_variety'),
            monitor: DSSUtilsService.getTextFilterDict($filter, 'monitor', 'cropPestDisease.filter_by_pest_disease'),
            risk: DSSUtilsService.getTextFilterDict($filter, 'risk', 'cropPestDisease.filter_by_risk_level'),
            run_on: DSSUtilsService.getTextFilterDict($filter, 'readable_run_date', 'cropPestDisease.filter_by_last_run'),
            run_status: DSSUtilsService.getTextFilterDict($filter, 'run_status', 'cropPestDisease.filter_by_run_status')
        };

        $scope.redirecttoMyFarm = function () {
            window.open('/dashboard/farm/#/', '_blank');
        };

    });