(function (angular) {
    "use strict";
    angular.module('PestDiseaseApp')
        .factory('MonitorInputFactory', MonitorInputFactory);

    function MonitorInputFactory($resource) {
        return $resource('api/v2.0/monitor/input/', {}, {
            create: {
                method: 'POST'
            }
        });
    }

})(angular);