angular.module('DssMainApp').config(['$routeProvider', '$resourceProvider', '$translateProvider',
    function ($routeProvider, $resourceProvider, $translateProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider
            .when('/', {
                redirectTo: '/pestdisease/osr/sclerotinia/'
            })
            .when('/irrigation/', {
                name: 'Dss Irrigation App',
                controller: 'dssIrrigationAppController',
                templateUrl: '/static/assets/khapps/dss/dss_irrigation/partials/dssIrrigationApp.html'
            })
            .when('/pestdisease/', (tenant_id == 'niab') ? {redirectTo: '/pestdisease/wheat/fusarium/'} : {
                name: 'Pest Disease Monitor',
                controller: 'MonitorSummaryController',
                templateUrl: '/static/assets/khapps/dss/dss_pestdisease/partials/PestDiseaseMonitor_Landing.html'
            })
            .when('/pestdisease/osr/sclerotinia/', {
                name: 'Oilseed Rape Sclerotinia Monitor',
                controller: 'OSRSclerotiniaMonitorController',
                templateUrl: '/static/assets/khapps/dss/dss_pestdisease/partials/PestDiseaseMonitor_Landing.html'
            })
            .when('/pestdisease/wheat/fusarium/', {
                name: 'Wheat Fusarium Monitor',
                controller: 'WheatFusariumMonitorController',
                templateUrl: '/static/assets/khapps/dss/dss_pestdisease/partials/PestDiseaseMonitor_Landing.html'
            })
            .when('/setup/', {
                name: 'Dss Setup App',
                controller: 'DssSetupListController',
                templateUrl: '/static/assets/khapps/dss/setup/crop_model_list_card.html'
            })
            .otherwise({
                redirectTo: '/pestdisease/'
            });


        var TranslateHelpers = {
            getTranslateDict: function (singname, pluname) {
                return {
                    card: {
                        title: singname
                    },
                    tableCard: {
                        title: pluname
                    },
                    form: {
                        title: singname + ' Add/Edit'
                    },
                    report: {
                        title: singname + ' Summary'
                    },
                    message: TranslateHelpers.getMessageDict(singname)

                };
            },
            getMessageDict: function (arg) {
                return {
                    save: {
                        success: 'Saved successfully.',
                        error: 'Failed to save',
                        info: 'Saving ' + arg + '... '
                    },
                    update: {
                        success: 'Updated successfully.',
                        error: 'Failed to update',
                        info: 'Updating ' + arg + '... '
                    },
                    remove: {
                        warning: 'Are you sure you want to delete ' + arg + ' :',
                        success: arg + ' has been deleted successfully.',
                        error: arg + ' has not been deleted.',
                        info: 'Deleting ' + arg + '... '
                    },
                    duplicate: {
                        success: arg + ' has been created successfully.',
                        error: arg + ' has not been created.',
                        info: 'Creating ' + arg + '... '
                    }
                };
            }
        };

        var commonEN = function () {
            return {
                sortBy: 'Sort by ',
                filterBy: 'Filter by '
            };
        };

        var CROP_IRRIGATION_EN = 'Crop Irrigation';
        var CROP_IRRIGATION_PLU_EN = 'Crop Irrigation';

        var cropIrrigationDictEN = function () {
            var dict = TranslateHelpers.getTranslateDict(CROP_IRRIGATION_EN, CROP_IRRIGATION_PLU_EN);
            dict.team = 'Team';
            dict.team_name = 'Team';
            dict.farm = 'Farm';
            dict.farm_name = 'Farm';
            dict.field = 'Field';
            dict.field_name = 'Field';
            dict.plot = 'Plot';
            dict.plot_name = 'Plot';
            dict.crop = 'Crop';
            dict.variety = 'Variety';
            dict.seasonal_actual_et_cm = 'Seasonal Actual ET (cm)';
            dict.last_updated = 'Last Updated';
            dict.date = 'Date';
            dict.applied_irrigation = 'Applied Irrigation (cm)';
            dict.effective_rainfall = 'Effective Rainfall (cm)';
            dict.irrigation_requirement = 'Irrigation Requirement (cm)';
            dict.smd = 'Soil Moisture Depletion (cm)';
            dict.root_depth = 'Root Depth (cm)';
            dict.actual_et = 'Actual ET (cm)';
            dict.reference_et = 'Reference ET (cm)';
            dict.crop_coefficient = 'Crop Coeff';
            dict.stress_factor = 'Stress Factor';
            dict.et_correction_factor = 'ET Correction Coeff';
            dict.smd_threshold = 'Soil Moisture Depletion Threshold (cm)';
            dict.smd_target = 'Soil Moisture Depletion Target (cm)';
            dict.crop_stress_model = 'Crop Stress Model';
            dict.effective_rainfall_model = 'Effective Rainfall Model';
            dict.root_growth_model = 'Root Growth Model';
            dict.et_yield_coefficient = 'ET-Yield Coefficient';
            dict.max_seasonal_et = 'Max Seasonal ET (cm)';
            dict.critical_moisture_content = 'Critical Moisture Content (%)';
            dict.hydraulic_conductivity = 'Hydraulic Conductivity (cm/day)';
            dict.permanent_wilting_point = 'Permanent Wilting Point Moisture Content (%)';
            dict.field_capacity_moisture_content = 'Field Capacity Moisture Content (%)';
            dict.percolation_rate = 'Percolation Rate (cm/hr)';
            dict.initial_soil_moisture_content = 'Initial Soil Moisture Content (%)';
            dict.bulk_density = 'Bulk Density (gm/cc)';
            return dict;
        };

        var CROP_PESTDISEASE_EN = 'Pest and Disease';
        var CROP_PESTDISEASE_PLU_EN = 'Pest and Disease';

        var cropPestDiseaseDictEN = function () {
            var dict = TranslateHelpers.getTranslateDict(CROP_PESTDISEASE_EN, CROP_PESTDISEASE_PLU_EN);
            dict.team = 'Team';
            dict.farm = 'Farm';
            dict.field = 'Field';
            dict.plot = 'Plot';
            dict.crop = 'Crop';
            dict.variety = 'Variety';
            dict.seasonal_actual_et_cm = 'Seasonal Actual ET (cm)';
            dict.last_updated = 'Last Updated';
            dict.no_data_fields_for_crop = 'No fields for crop. Please create crop using MyFarm App.';
            dict.no_locations = 'No location found. Please add location using MyFarm app.';
            dict.pest_disease = 'Pest / disease';
            dict.risk_level = 'Risk level';
            dict.todays_risk = 'Today\'s risk';
            dict.pest_disease_risk = 'Pest / disease risk';
            dict.fusarium_risk = 'Fusarium risk';
            dict.information = 'Information';
            dict.filter_by_risk_level = 'Filter by risk level';
            dict.filter_by_pest_disease = 'Filter by pest / disease';
            dict.filter_by_variety = 'Filter by variety';
            dict.filter_by_crop = 'Filter by crop';
            dict.filter_by_plot = 'Filter by plot';
            dict.filter_by_team = 'Filter by team';
            dict.filter_by_farm = 'Filter by farm';
            dict.filter_by_field = 'Filter by field';
            dict.filter_by_location = 'Filter by location';
            dict.filter_by_location_type = 'Filter by location type';
            dict.filter_by_previous_crop = 'Filter by previous crop';
            dict.filter_by_cultivation_method = 'Filter by cultivation method';
            dict.filter_by_date_of_ear_emergence = 'Filter by date of ear emergence';
            dict.filter_by_risk = 'Filter by risk level';
            dict.filter_by_last_run = 'Filter by last run';
            dict.filter_by_run_status = 'Filter by run status';
            dict.location = 'Location';
            dict.location_type = 'Location type';
            dict.previous_crop = 'Previous crop';
            dict.cultivation_method = 'Cultivation method';
            dict.date_of_ear_emergence = 'Expected date of ear emergence';
            dict.model_run_on = 'Last model run';
            dict.monitor_input = 'Monitor input';
            dict.run_on = 'Model run on';
            dict.risk_summary = 'Risk summary';
            dict.risk_charts = 'Risk charts';
            dict.recommendation = 'Recommendation';
            dict.weather_data_source = 'Weather data source';
            dict.interpolated_weather = 'Interpolated forecast and / or interpolated actual';
            dict.pre_emergence_risk = 'Pre-emergence risk';
            dict.post_emergence_risk = 'Post-emergence risk';
            dict.fusarium_ear_blight_risk = 'Fusarium ear blight risk';
            dict.fusarium_ear_blight_risk_score = 'Fusarium ear blight risk score';
            dict.run_status = 'Run status';
            dict.record_parameters = 'Record Parameters';
            dict.record_flowering_status = 'Record Flowering Status';
            dict.model_info_pdf = 'Model info PDF';
            dict.nil_risk = 'Nil risk';
            dict.low_risk = 'Low risk';
            dict.medium_risk = 'Medium risk';
            dict.high_risk = 'High risk';
            dict.nearest_monitoring_site_details = 'Nearest monitoring site details';
            dict.monitor_site_data_last_updated = 'Last updated';
            dict.sclerotinia_germination = 'Sclerotial germination';
            dict.level_of_sclerotinia_spores = 'Level of sclerotinia spores';
            dict.petals_testing_positive_latest_result = 'Petals testing positive latest result';
            dict.overall_risk = 'Overall risk';
            dict.petal_infection = 'Petal infection';
            dict.description = 'Description';
            dict.interpretation = 'Interpretation';
            dict.credit = 'Credit';
            dict.risk_level_chart = 'Risk Level Chart';
            return dict;
        };

        var setupDictEN = function () {
            var dict = {};
            dict.crop_model = 'Crop model';
            dict.name = 'Name';
            dict.crop = 'Crop';
            dict.category = 'Category';
            dict.version = 'Version';
            dict.availability = 'Availability';
            dict.is_available = 'is available';
            dict.is_not_available = 'is not available';
            dict.edit_crop_model = 'Edit crop model';
            dict.add_crop_model = 'Add crop model';
            dict.filter_by_name = 'Filter by name';
            dict.filter_by_crop = 'Filter by crop';
            dict.filter_by_category = 'Filter by category';
            dict.filter_by_version = 'Filter by version';
            return dict;
        };


        $translateProvider.translations('en', {
            common: commonEN(),
            cropIrrigation: cropIrrigationDictEN(),
            cropPestDisease: cropPestDiseaseDictEN(),
            setup: setupDictEN()
        });
    }
]);