angular.module("KisanHubDashboardApp").config(["$routeProvider", "$resourceProvider", "$translateProvider",
    function ($routeProvider, $resourceProvider, $translateProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider
            .when("/", {
                name: 'Dashboard',
                template: '<dashboard></dashboard>',
            })
            .otherwise({
                redirectTo: '/'
            });

        $translateProvider.translations('en', {
            operation_is_overdue: 'Operation is overdue',
            operation_is_planned: 'Operation is planned',
            operation_is_completed: 'Operation is completed',
            change_operation_status: 'Change operation status',
            are_you_sure_to_mark_this_operation_as_pending: "Are you sure to mark this operation as pending?",
            are_you_sure_to_mark_this_operation_as_completed: "Are you sure to mark this operation as completed?"
        });
    }
]);