angular.module('PestDiseaseApp').config(["$routeProvider", "$resourceProvider", "$translateProvider",
    function ($routeProvider, $resourceProvider, $translateProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider
            .when("/", {
                redirectTo: '/strawberrypowderymildew/'
            })
            .otherwise({
                redirectTo: '/'
            });
        $translateProvider.translations('en', {
            wheatfusarium: 'Wheat Fusarium',
            strawberrypowderymildew: 'Strawberry Powdery Mildew',
            no_labs: 'No soil analyis labs found',
            no_results: 'No soil analyis test results found',
            add_item: 'Creating',
            edit_item: 'Updating',
            create_success: 'Successfully created',
            create_failed: 'Failed creation',
            update_success: 'Successfully updated',
            update_failed: 'Failed update'
        });
    }
]);