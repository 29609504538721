(function (angular) {
    "use strict";
    angular.module('DssSetupApp')
        .factory('CropModelFactory', CropModelFactory);

    function CropModelFactory($resource) {
        return $resource('api/v2.0/crop_model/cropmodel/:slug/', {}, {
            query: {
                method: 'GET',
                isArray: false
            },
            create: {
                method: 'POST'
            },
            get: {
                method: 'GET'
            },
            update: {
                method: 'PUT'
            }
        });
    }

})(angular);

(function (angular) {
    "use strict";
    angular.module('DssSetupApp')
        .factory('CropModelCategoryFactory', CropModelCategoryFactory);

    function CropModelCategoryFactory($resource) {
        return $resource('api/v2.0/crop_model/cropmodelcategory/', {}, {
            query: {
                method: 'GET',
                isArray: false
            }
        });
    }

})(angular);

(function (angular) {
    "use strict";
    angular.module('DssSetupApp')
        .factory('CropModelTypeFactory', CropModelTypeFactory);

    function CropModelTypeFactory($resource) {
        return $resource('api/v2.0/crop_model/cropmodeltype/', {}, {
            query: {
                method: 'GET',
                isArray: false
            }
        });
    }

})(angular);