(function(angular){
    "use strict";
    angular.module('PestDiseaseApp')
        .factory('MonitorOutputFactory', MonitorOutputFactory);

    function MonitorOutputFactory($resource){
        return $resource('api/v2.0/monitor/data/:model_run_slug/', {}, {
            query: {
                isArray: false
            },
            get: {
                method: 'GET'
            }
        });
    }

})(angular);