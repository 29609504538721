(function (angular) {
    "use strict";
    angular.module('DssSetupApp')
        .controller('DssSetupListController', function DssSetupListController($scope,
                                                                              $rootScope,
                                                                              $mdDialog,
                                                                              NgTableParams,
                                                                              $filter,
                                                                              CropModelFactory,
                                                                              DSSUtilsService, GenericUtilsService) {

            $rootScope.page_id = "decisionsupport";
            $rootScope.sub_app_page_id = "decisionsupport_setup";
            $rootScope.currentNavItem = "";
            $scope.showEmptyState = false;

            $scope.CropModelCard = [{
                card_title: $filter('translate')('setup.crop_model'),
                kh_card_content_template: '/static/assets/khapps/dss/setup/crop_model_list.html',
                card_flex: 100,
                show_progress_bar: true
            }];

            $scope.$on("addedCropModel",
                function (broadcast, response) {
                    $scope.all_models.unshift(response.data);
                    $scope.cropModelTable = new NgTableParams({}, {dataset: $scope.all_models});
                    $scope.showEmptyState = false;
                }
            );

            function getCropModelList(pageNo) {
                CropModelFactory.query({include_public: "yes", page: pageNo}, function (response) {
                    $scope.all_models = $scope.all_models.concat(response.data);
                    if (response.next) {
                        getCropModelList(response.current_page + 1);
                    }
                    $scope.cropModelTable = new NgTableParams({}, {dataset: $scope.all_models});
                    $scope.showEmptyState = (response.total_count === 0);
                })
            }

            $scope.all_models = [];
            getCropModelList(1);

            $scope.editCropModel = function (slug) {
                $mdDialog.show({
                    templateUrl: '/static/assets/khapps/dss/setup/crop_model_dialog_form.html',
                    controller: 'AddEditCropModelController',
                    locals: {
                        slug: slug
                    }
                }).then(function (response) {
                    $scope.all_models = GenericUtilsService.updateListAfterEdit($scope.all_models, "slug", slug, response.data);
                    $scope.cropModelTable = new NgTableParams({}, {dataset: $scope.all_models});
                });
            };

            $scope.cropModelFilters = {
                name: DSSUtilsService.getGenericFilterDict($filter, 'name', 'text', 'setup.filter_by_name'),
                crop: DSSUtilsService.getGenericFilterDict($filter, 'crop', 'text', 'setup.filter_by_crop'),
                category: DSSUtilsService.getGenericFilterDict($filter, 'category_name', 'text', 'setup.filter_by_category'),
                version: DSSUtilsService.getGenericFilterDict($filter, 'version', 'text', 'setup.filter_by_version')
            };

        });


})(angular);
