angular.module("DssIrrigationApp").factory('SWBMModelRunFactory', ['$resource', function ($resource) {
    return $resource('/api/v1.0/swbm/swbmmodelfieldplotcrop/', {}, {
        query: {
            params: {growing_season: '@growing_season'},
            url: '/api/v1.0/swbm/swbmmodelfieldplotcrop/',
            isArray: true
        }
    });
}]);

angular.module("DssIrrigationApp").factory('SWBMModelOutputFactory', ['$resource', function ($resource) {
    return $resource('/api/v1.0/swbm/output/', {}, {
        get: {
            params: {crop_model_run_slug: '@crop_model_run_slug'},
            url: '/api/v1.0/swbm/output/:crop_model_run_slug/',
            method: 'GET'
        },
        query: {
            params: {growing_season: '@growing_season'},
            url: '/api/v1.0/swbm/swbmmodelfieldplotcrop/',
            isArray: true
        }
    });
}]);

