angular.module('DssSetupApp')
    .controller('AddEditCropModelController', function AddEditCropModelController($rootScope,
                                                                                  $scope,
                                                                                  $filter,
                                                                                  $khMessage,
                                                                                  $mdDialog,
                                                                                  KhutilsCropListFactory,
                                                                                  CropModelCategoryFactory,
                                                                                  CropModelTypeFactory,
                                                                                  CropModelFactory,
                                                                                  CommunicationLanguageFactory,
                                                                                  slug,
                                                                                  fileUploads,
                                                                                  DjangoSiteFactory,
                                                                                  GenericUtilsService) {

        var CropModelForm = function (crop_data, categories, types, sites) {
            return [{
                className: "flex-33",
                type: "input",
                key: "name",
                templateOptions: {
                    label: "Name",
                    required: true,
                    maxlength: 128
                }
            }, {
                className: "flex-33",
                type: "input",
                key: "short_name",
                templateOptions: {
                    label: "Short name",
                    maxlength: 64
                }
            }, {
                className: "flex-33",
                type: "input",
                key: "version",
                templateOptions: {
                    type: 'number',
                    label: "Version",
                    required: true
                }
            }, {
                key: "crop",
                className: "flex-33",
                type: "searchable_select",
                templateOptions: {
                    valueProp: 'crop_id',
                    labelProp: 'crop_name',
                    label: "Crop",
                    options: crop_data,
                    required: true
                }
            }, {
                key: "category",
                className: "flex-33",
                type: "searchable_select",
                templateOptions: {
                    label: "Category",
                    options: categories,
                    required: true
                }
            }, {
                key: "type",
                className: "flex-33",
                type: "searchable_select",
                templateOptions: {
                    label: "Type",
                    options: types
                }
            }, {
                className: "flex-100",
                type: "textarea",
                key: "description",
                templateOptions: {
                    label: "Description",
                    rows: 3,
                    required: true
                }
            }, {
                className: "flex-100",
                type: "textarea",
                key: "interpretation",
                templateOptions: {
                    label: "Interpretation",
                    rows: 3,
                    required: true
                }
            }, {
                className: "flex-33",
                type: "input",
                key: "region",
                templateOptions: {
                    label: "Region"
                }
            }, {
                className: "flex-33",
                type: "input",
                key: "season",
                templateOptions: {
                    label: "Season"
                }
            }, {
                className: "flex-33",
                type: "searchable_select",
                key: "site_ids",
                templateOptions: {
                    label: "Sites",
                    options: sites,
                    multiple: true,
                    valueProp: 'id',
                    labelProp: 'name'
                }
            }, {
                className: "flex-50",
                type: "input",
                key: "external_links",
                templateOptions: {
                    label: "External links"
                }
            }, {
                className: "flex-50",
                type: "input",
                key: "credit",
                templateOptions: {
                    label: "Credit"
                }
            }, {
                className: "flex-50",
                type: "checkbox",
                key: "is_available",
                defaultValue: true,
                templateOptions: {
                    label: "Is available"
                }
            }, {
                className: "flex-50",
                type: "checkbox",
                key: "is_default",
                defaultValue: true,
                templateOptions: {
                    label: "Is default"
                }
            }];
        };

        $scope.crop_list = KhutilsCropListFactory.query(function (crop_data) {
            CropModelCategoryFactory.query(function (categories) {
                CropModelTypeFactory.query(function (types) {
                    function fetchCommunicationLanguages(pageNo) {
                        DjangoSiteFactory.query(function (sites) {
                            CommunicationLanguageFactory.query({page: pageNo}, function (response) {
                                $scope.communication_languages = $scope.communication_languages.concat(response.data);
                                if (response.next) {
                                    fetchCommunicationLanguages(pageNo + 1);
                                } else {
                                    $scope.cropModelForm = CropModelForm(crop_data, categories.data, types.data, sites);
                                }
                            });
                        });
                    }

                    $scope.communication_languages = [];
                    fetchCommunicationLanguages(1);
                })
            })
        });
        if (slug) {
            $scope.dialogTitle = $filter('translate')('setup.edit_crop_model');
            CropModelFactory.get({slug: slug, content: "edit"}, function (response) {
                $scope.model = response.data;
                if (response.data.attachments.length > 0) {
                    fileUploads.displayExistingFiles(response.data.attachments, "mongo");
                }
            })
        } else {
            $scope.dialogTitle = $filter('translate')('setup.add_crop_model');
            $scope.model = {};
        }

        $scope.sendData = function () {
            $scope.model.attachments = fileUploads.saveFiles();

            if ($scope.model.slug) {
                CropModelFactory.update({slug: slug}, $scope.model, function (response) {
                    $khMessage.show($filter('translate')('updated_successfully'));
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleErrorV2($filter, $khMessage, error);
                })
            } else {
                CropModelFactory.create($scope.model, function (response) {
                    $khMessage.show($filter('translate')('created_successfully'));
                    $mdDialog.hide(response);
                }, function (error) {
                    GenericUtilsService.handleErrorV2($filter, $khMessage, error);
                })
            }
        };
        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.deleteRecommendation = function (lang, key) {
            delete $scope.model.recommendation[lang][key];
        };

        $scope.addRecommendation = function (lang) {
            if (!$scope.model.recommendation) {
                $scope.model.recommendation = {
                    lang: {
                        '': ''
                    }
                };
            } else if (!$scope.model.recommendation[lang]) {
                $scope.model.recommendation[lang] = {
                    '': ''
                };
            } else {
                $scope.model.recommendation[lang][''] = '';
            }
        };


        $scope.editKey = function (lang, value, oldValue) {
            $scope.model.recommendation[lang][value] = $scope.model.recommendation[lang][oldValue];
            delete $scope.model.recommendation[lang][oldValue];
        };
    });

