angular.module("KisanHubDashboardApp").run(["$http", "$rootScope", "UserSettingsFactory", "DateTimeHelperFactory",
    function ($http, $rootScope, UserSettingsFactory, DateTimeHelperFactory) {
        $http.defaults.xsrfCookieName = "csrftoken";
        $http.defaults.xsrfHeaderName = "X-CSRFToken";

        UserSettingsFactory.get(function (result) {
            if (result.DEFAULT_USER_READABLE_DATE_FORMAT) {
                $rootScope.userSettingDateFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                    result.DEFAULT_USER_READABLE_DATE_FORMAT);
            } else {
                $rootScope.userSettingDateFormat = 'DD MMM, YYYY';
            }
            if (result.DEFAULT_USER_READABLE_TIME_FORMAT) {
                $rootScope.userSettingTimeFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                    result.DEFAULT_USER_READABLE_TIME_FORMAT);
            } else {
                $rootScope.userSettingTimeFormat = 'HH:mm';
            }
        });
    }]);