angular.module("DssIrrigationApp").config(['$routeProvider', '$resourceProvider',
    function ($routeProvider, $resourceProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider
            .when('/irrigation', {
                name: 'Dss Irrigation App',
                controller: 'dssIrrigationAppController',
                templateUrl: '/static/assets/khapps/dss/dss_irrigation/partials/dssIrrigationApp.html'
            })
            .otherwise({
                redirectTo: '/'
            });
    }
]);