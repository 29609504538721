angular.module("DssIrrigationApp").controller('dssIrrigationAppController',
    function ($scope, $rootScope, $mdDialog, $filter, NgTableParams, $khMessage,
              $khPulse, FarmSummaryFactory, SWBMModelRunFactory, DateTimeHelperFactory, DSSUtilsService) {

        DSSUtilsService.initController($scope, $rootScope, $filter);
        DSSUtilsService.appSideBar($rootScope, 'decisionsupport', 'decisionsupport_irrigation');

        $scope.tableFilters = {
            team_name: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'team_name'),
            farm_name: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'farm_name'),
            field_name: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'field_name'),
            plot_name: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'plot_name'),
            crop_name: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'crop'),
            variety_name: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'variety'),
            seasonal_actual_et_cm: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'value'),
            last_updated: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'updated_ts'),
        };

        $scope.$on('filter:selections_updated', function (obj, filterValue) {
            if (filterValue.hasOwnProperty('growing_season')) {
                if ($rootScope.selected_growing_season.value) {
                    load_farm_field_summary();
                }

            }
        });

        $scope.$on('$routeChangeSuccess', function (scope, next, previous) {
            if (previous) load_farm_field_summary();
        });

        $scope.AnalysisCards = [
            {
                card_title: 'Crop Irrigation',
                kh_card_content_template: '/static/assets/khapps/dss/dss_irrigation/partials/FarmFieldCropTable.html',
                kh_card_toolbar_filters_template: '',
                card_flex: 100,
                show_progress_bar: true
            }
        ];

        function load_farm_field_summary() {
            $khPulse.show();
            if (angular.isUndefined($rootScope.selected_growing_season)) {
                $rootScope.selected_growing_season = {};
                $rootScope.selected_growing_season.name = $scope.planting_season_current;
                $rootScope.selected_growing_season.value = $scope.planting_season_current;
            }
            SWBMModelRunFactory.query({growing_season: $rootScope.selected_growing_season.value,
                team: $rootScope.selected_team}, function (result_data) {
                $scope.farms_with_plots = [];
                for (var i = 0; i < result_data.length; i++) {
                    if (result_data[i].updated_ts)
                        result_data[i].readable_updated_ts = DateTimeHelperFactory.convertDateTimeFormatUsingMoment(result_data[i].updated_ts);
                    $scope.farms_with_plots.push(result_data[i]);
                }
                $scope.tableParams = new NgTableParams({}, {dataset: $scope.farms_with_plots});
                $khMessage.hide();
                $khPulse.hide(0);
            }, function (error) {
                if (error.status) {
                    message = 'Failed to query.';
                    $khMessage.show(message);
                }
                $khPulse.hide(0);
            });
        }

        $scope.view = function (field_plot_crop) {
            $mdDialog.show({
                controller: 'dssIrrigationModelResultDetailController',
                skipHide: true,
                multiple: true,
                templateUrl: '/static/assets/khapps/dss/dss_irrigation/partials/CropIrrigation_View.html',
                locals: {
                    full_plot_name: field_plot_crop.plot_name,
                    crop_model_run_slug: field_plot_crop.run_id
                },
                clickOutsideToClose: true
            });
        };

    });

angular.module("DssIrrigationApp").controller('dssIrrigationModelResultDetailController',
    function ($scope, $rootScope, $mdDialog, $filter, NgTableParams, leafletData,
              SWBMModelOutputFactory, DSSIrrigationChartConfigFactory, full_plot_name, crop_model_run_slug, 
              $khMessage, DSSUtilsService) {

        Highcharts.setOptions({
            lang: {
                resetZoom: 'Show full season'
            }
        });

        DSSUtilsService.initController($scope, $rootScope, $filter);
        DSSUtilsService.appSideBar($rootScope, 'decisionsupport', 'decisionsupport_irrigation');

        $scope.full_plot_name = full_plot_name;
        $scope.AnalysisCards = [
            {
                card_title: $scope.full_plot_name,
                kh_card_content_template: '/static/assets/khapps/dss/dss_irrigation/partials/SWBMModelTimeSeriesDataTable.html',
                kh_card_toolbar_filters_template: '',
                card_flex: 100,
                show_progress_bar: false
            }
        ];

        $scope.tableFilters = {
            date: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'date'),
            applied_irrigation: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'applied_irrigation'),
            effective_rainfall: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'effective_rainfall'),
            irrigation_requirement: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'irrigation_requirement'),
            smd: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'smd'),
            root_depth: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'root_depth'),
            actual_et: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'actual_et'),
            reference_et: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'reference_et'),
            stress_factor: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'stress_factor'),
            et_correction_factor: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'et_correction_factor'),
            smd_threshold: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'smd_threshold'),
            smd_target: DSSUtilsService.getFilterDict($filter, 'cropIrrigation', 'smd_target')
        };

        getIrrigationChartData();
        getIrrigationChartData();

        function getIrrigationChartData() {
            SWBMModelOutputFactory.get({crop_model_run_slug: crop_model_run_slug}, function (result_data) {
                if (result_data.output.length === 0) {
                    $khMessage.show('The model has been reset, please refresh the page. ');
                }
                $scope.model_options = result_data.model_options;
                $scope.output = result_data.output;
                $scope.output_headers = result_data.output_headers;
                $scope.param_dict = result_data.param_dict;
                $scope.tableParams = new NgTableParams({}, {dataset: $scope.output});

                $scope.DSSIrrigationChartConfig = DSSIrrigationChartConfigFactory.getChartConfig($scope.param_dict);
                $scope.DSSIrrigationChartConfig.series[0].data = $scope.param_dict.eff_rain;
                $scope.DSSIrrigationChartConfig.series[1].data = $scope.param_dict.irrigation;
                $scope.DSSIrrigationChartConfig.series[2].data = $scope.param_dict.act_et;
                $scope.DSSIrrigationChartConfig.series[3].data = $scope.param_dict.root_depth;
                $scope.DSSIrrigationChartConfig.series[4].data = $scope.param_dict.smd;
                $scope.DSSIrrigationChartConfig.series[5].data = $scope.param_dict.smd_threshold;
                $scope.DSSIrrigationChartConfig.series[6].data = $scope.param_dict.smd_target;
                $scope.DSSIrrigationChartConfig.series[7].data = $scope.param_dict.recommended_irrigation;
                $scope.DSSIrrigationChartConfig.series[8].data = $scope.param_dict.crop_coeff;

                $scope.showChart = true;
            });
        }
    });
