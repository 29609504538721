angular.module("DssSetupApp").config(["$routeProvider", "$resourceProvider",
    function ($routeProvider, $resourceProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
        $routeProvider
            .when("/setup/", {
                name: 'Dss Setup App',
                controller: 'dssSetupListController',
                templateUrl: '/static/assets/khapps/dss/setup/dssSetupApp.html'
            })
            .otherwise({
                redirectTo: '/setup/'
            });
    }
]);