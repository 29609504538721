angular.module('PestDiseaseApp').run(["$http", "$rootScope", "formlyConfig", "formlyValidationMessages",
    function ($http, $rootScope, formlyConfig, formlyValidationMessages) {
        $http.defaults.xsrfCookieName = "csrftoken";
        $http.defaults.xsrfHeaderName = "X-CSRFToken";

        formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = 'fc.$touched || form.$submitted';
        formlyValidationMessages.addStringMessage('required', 'This field is required');

        //$rootScope.in_app_add_button = '';
        //$rootScope.in_app_links_template = '';
        $rootScope.view_as_template = "";
        $rootScope.select_filters_template = "";

        $rootScope.tableview = true;
        $rootScope.mapview = false;
        $rootScope.cardview = false;

        $rootScope.views = [
            {name: 'Map View', value: 'mapview', icon: 'google-maps'},
            {name: 'Card View', value: 'cardview', icon: 'view-grid'},
            {name: 'Table View', value: 'tableview', icon: 'table'}
        ];

        $rootScope.changeView = function (view) {
            $rootScope.activeView = view;
            switch (view) {
                case "mapview":
                    $rootScope.tableview = false;
                    $rootScope.mapview = true;
                    $rootScope.cardview = false;
                    break;
                case "tableview":
                    $rootScope.tableview = true;
                    $rootScope.mapview = false;
                    $rootScope.cardview = false;
                    break;
                case "cardview":
                    $rootScope.tableview = false;
                    $rootScope.mapview = false;
                    $rootScope.cardview = true;
                    break;
            }
        };

    }]);

//soilanalysisapp.config(function (formlyConfigProvider) {
//
//    formlyConfigProvider.setWrapper({
//        name: 'validation',
//        types: ['input'],
//        templateUrl: 'error-messages.html'
//    });
//
//    formlyConfigProvider.setWrapper({
//        name: 'panel',
//        templateUrl: 'panel.html'
//    });
//
//});