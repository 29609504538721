(function (angular) {
    'use strict';
    angular.module('PestDiseaseApp')
        .controller('OSRSclerotiniaRecordFloweringStatusController', ['$rootScope',
            '$scope',
            '$mdDialog',
            'flowering_status_response',
            'OSRGrowthConstantsFactory',
            'OSRGrowthFactory',
            OSRSclerotiniaRecordFloweringStatusController
        ]);

    function OSRSclerotiniaRecordFloweringStatusController($rootScope,
        $scope,
        $mdDialog,
        flowering_status_response,
        OSRGrowthConstantsFactory,
        OSRGrowthFactory) {

        $scope.enable_submit = false;
        $scope.dialogTitle = 'Record Flowering Status';
        $scope.startStatus = angular.copy(flowering_status_response);
        $scope.startStatus['status'] = 'START';
        $scope.fullStatus = angular.copy(flowering_status_response);
        $scope.fullStatus['status'] = 'FULL';
        $scope.endStatus = angular.copy(flowering_status_response);
        $scope.endStatus['status'] = 'END';

        OSRGrowthConstantsFactory.query(function (response) {
            $scope.FloweringStatusForm = [
                {
                    className: 'flex-50',
                    type: 'searchable_select',
                    key: 'status',
                    templateOptions: {
                        label: 'Flowering Status',
                        options: response.growth_status_list,
                        disabled: true
                    }
                },
                {
                    className: 'flex-50',
                    type: 'datepicker',
                    key: 'observation_date',
                    templateOptions: {
                        label: 'Observation Date',
                        placeholder: 'DD/MM/YYYY'
                    }
                }
            ];
            $scope.enable_submit = true;
        });

        function fetchPreviousData() {
            OSRGrowthFactory.query(
                {
                    growing_season: flowering_status_response.growing_season,
                    latitude: flowering_status_response.latitude,
                    longitude: flowering_status_response.longitude
                }, function (response) {
                    angular.forEach(response, function (record) {
                        if (record.status === 'START') {
                            $scope.startStatus.observation_date = moment(record.observation_date, 'YYYY-MM-DDTHH:mm:ss').toDate();
                            $scope.startStatus['id'] = record.id;
                        }
                        if (record.status === 'FULL') {
                            $scope.fullStatus.observation_date = moment(record.observation_date, 'YYYY-MM-DDTHH:mm:ss').toDate();
                            $scope.fullStatus['id'] = record.id;
                        }
                        if (record.status === 'END') {
                            $scope.endStatus.observation_date = moment(record.observation_date, 'YYYY-MM-DDTHH:mm:ss').toDate();
                            $scope.endStatus['id'] = record.id;
                        }
                    });
                }, function (error) {
                });
        }
        fetchPreviousData();


        function formatDate(data) {

            if (data.observation_date) {
                data.observation_date = moment(new Date(data.observation_date.getFullYear(), data.observation_date.getMonth(), data.observation_date.getDate(), 0, 0, 0)).format('YYYY-MM-DDTHH:mm:ss');
            }
        }

        function submitFloweringStatusData() {
            var flowering_data_list = [$scope.startStatus, $scope.fullStatus, $scope.endStatus];
            angular.forEach(flowering_data_list, function (data, idx, arr) {
                formatDate(data);
                if (idx === arr.length - 1) {
                    $mdDialog.hide(flowering_data_list);
                }
            });
        }
        $scope.submitFloweringStatusData = submitFloweringStatusData;

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

    }
})(angular);