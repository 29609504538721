
(function (angular) {
    "use strict";
 angular
        .module('KisanHubDashboardApp')
        .config(function ($mdDateLocaleProvider, $translateProvider) {
            $translateProvider.translations('en', {
                farm: "Farm",
                field: "Field",
                date: 'Date',
                parameters: 'Parameters',
                value: 'Value',
                cancel:'Cancel',
                selected:'Selected',
                remove:'Remove',
                all:'All',
                select:'Select',
                weather:'Weather',
                parameter:'Parameter',
                actual:'Actual',
                forecast:'Forecast',
                start_date:'Start Date',
                time:'Time',
                date_time:'Date Time',
                table_view:'Table view',
                chart_view:'Chart view',
                end_date:'End date',
                frequency:'Frequency',
                source:'Source',
                max_temp:'Maximum temperature',
                min_temp:'Minimum temperature',
                rainfall:'Rainfall',
                wind_speed:'Wind Speed',
                wind_direction:'Wind Direction',
                ref_et_penman:'Ref ET',
                avg:'Average',
                temperature:'Temperature',
                total:'Total'
            });
        });
})(angular);