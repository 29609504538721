(function(angular){
    "use strict";
    angular.module('PestDiseaseApp')
        .factory('FusariumConstantsFactory', FusariumConstantsFactory);

    function FusariumConstantsFactory($resource){
        return $resource('api/v2.0/monitor/fusarium-constants/', {}, {
            get: {
                method: 'GET'
            }
        });
    }

})(angular);