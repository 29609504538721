angular.module('PestDiseaseApp')
    .controller('PestDiseaseChartController',
        function PestDiseaseChartController($scope,
                                            $filter,
                                            $khMessage,
                                            MonitorOutputFactory,
                                            monitor_output, GenericUtilsService) {

            $scope.monitorOutput = monitor_output;

            $scope.monitorChartCards = [
                {
                    card_title: 'Recommendation',
                    kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/partials/recommendation.html',
                    card_flex: 100
                },
                {
                    card_title: $scope.monitorOutput.crop + ' - ' + $scope.monitorOutput.monitor,
                    kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/partials/pest_disease_chart.html',
                    kh_card_toolbar_filters_template: '',
                    card_flex: 100,
                    show_progress_bar: true
                },
                {
                    card_title: 'Monitor Information',
                    kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/partials/MonitorInformation.html',
                    kh_card_toolbar_filters_template: '',
                    card_flex: 100
                }
            ];

            $scope.pestDiseaseTooltip = function () {
                return new Date(this.point.x).toDateString() + '<br>' + '<b>' + this.point.tool_tip_text + '</b>';
            };

            (function getMonitorOutput() {
                MonitorOutputFactory.get({model_run_slug: $scope.monitorOutput.model_run_slug}, function (response) {
                    $scope.pestDiseaseCharts = [];
                    for (var key in response.data.chart_data) {
                        if (response.data.chart_data.hasOwnProperty(key)) {
                            $scope.pestDiseaseCharts.push(response.data.chart_data[key]);
                        }
                    }
                    $scope.monitor_description = response.metadata.monitor_description;
                    $scope.monitor_interpretation = response.metadata.monitor_interpretation;
                    $scope.credit = response.metadata.credit;
                    $scope.recommendation = response.metadata.recommendation;
                    $scope.one_week_data = response.data.one_week_data;
                }, function (error) {
                    GenericUtilsService.handleErrorV2($filter, $khMessage, error);
                });
            })();

        }
    )

