export default angular.module('DssMainApp', [
    'KhBase',
    'KhChart',
    'leaflet-directive',
    'PestDiseaseApp',
    'DssIrrigationApp',
    'DssSetupApp',
    'ApiUtils.settings.djangoSite',
    'ApiUtils.crowdsource'
]);
