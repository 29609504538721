angular.module('DssMainApp').run(['$http', '$rootScope', '$routeParams', '$location', '$mdDialog', 'DateTimeHelperFactory',
    'UserSettingsFactory',
    function ($http, $rootScope, $routeParams, $location, $mdDialog, DateTimeHelperFactory, UserSettingsFactory) {
        $http.defaults.xsrfCookieName = 'csrftoken';
        $http.defaults.xsrfHeaderName = 'X-CSRFToken';

        $rootScope.in_app_add_button = '/static/assets/khapps/dss/dss_navbar_add_button.html';
        $rootScope.select_filters_template = '/static/assets/khapps/dss/dss_main/partials/includes/navbar_filters.html';
        $rootScope.in_app_links_template = '/static/assets/khapps/dss/dss_main/partials/includes/navbar_in_app_links.html';
        $rootScope.is_dss_admin = permissions.is_dss_admin;
        $rootScope.is_superuser = permissions.is_superuser;
        $rootScope.is_site_admin = permissions.is_site_admin;
        $rootScope.is_reg_admin = permissions.is_reg_admin;
        $rootScope.can_manage_site_teams = permissions.is_reg_admin | permissions.is_site_admin;

        $rootScope.tableview = true;
        $rootScope.mapview = false;
        $rootScope.cardview = false;

        $rootScope.views = [
            {name: 'Map View', value: 'mapview', icon: 'google-maps'},
            {name: 'Table View', value: 'tableview', icon: 'table'}
        ];

        $rootScope.changeView = function (view) {
            switch (view) {
                case 'mapview':
                    $rootScope.tableview = false;
                    $rootScope.mapview = true;
                    $rootScope.cardview = false;
                    break;
                case 'tableview':
                    $rootScope.tableview = true;
                    $rootScope.mapview = false;
                    $rootScope.cardview = false;
                    break;
                case 'cardview':
                    $rootScope.tableview = false;
                    $rootScope.mapview = false;
                    $rootScope.cardview = true;
                    break;
            }
        };

        var DSS_IRRIGATION_TABS_LIST = [
            {name: "Crop Irrigation", value: "irrigation"},
            {name: "Setup", value: "irrigationsetup"}
        ];

        var DSS_PESTDISEASE_TABS_LIST = [
            {name: "Pest and Disease", value: "pestdisease"},
            {name: "Setup", value: "pestdiseasesetup"}
        ];

        $rootScope.isNavTabsActive = function () {
            switch ($location.path()) {
                case '/':
                    return true;
                case '/irrigation':
                    $rootScope.navbarTabs = DSS_IRRIGATION_TABS_LIST.navbarTabs;
                    return true;
                case '/pestdisease':
                    $rootScope.navbarTabs = DSS_PESTDISEASE_TABS_LIST.navbarTabs;
                    return true;
                default:
                    return true;
            }
        };

        UserSettingsFactory.get(function (result) {
            if (result.DEFAULT_USER_READABLE_DATE_TIME_FORMAT) {
                $rootScope.userSettingDateTimeFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                    result.DEFAULT_USER_READABLE_DATE_TIME_FORMAT);
            } else {
                $rootScope.userSettingDateTimeFormat = 'DD MMM, YYYY HH:mm';
            }
            if (result.DEFAULT_USER_READABLE_DATE_FORMAT) {
                $rootScope.userSettingDateFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                    result.DEFAULT_USER_READABLE_DATE_FORMAT);
            } else {
                $rootScope.userSettingDateFormat = 'DD MMM, YYYY';
            }
            if (result.DEFAULT_USER_READABLE_TIME_FORMAT) {
                $rootScope.userSettingTimeFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                    result.DEFAULT_USER_READABLE_TIME_FORMAT);
            } else {
                $rootScope.userSettingTimeFormat = 'HH:mm';
            }
        });


    }]);
