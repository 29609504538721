angular.module('PestDiseaseApp').service('OSRSclerotiniaChartConfigFactory', [function () {


        this.getChartConfig = function () {
            return {
                options: {
                    exporting: {
                        enabled: false
                    },
                    lang: {
                        noData: "Loading Data..."
                    },
                    chart: {
                        zoomType: 'xy',
                        plotBorderWidth: 2
                    },
                    tooltip: {
                        shared: true
                    }
                },
                title: {
                    text: 'Sclerotinia Risk Prediction'
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'top',
                    floating: true,
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    type: 'datetime',
                    plotBands: [{
                        from: Date.UTC(2017, 3, 25),
                        to: Date.UTC(2017, 4, 10),
                        color: 'rgba(68, 170, 213, 0.2)',
                        label: {
                            text: 'Flowering Stage'
                        }
                    }],
                    plotLines: [{
                        label: {text: 'Now', rotation: -2, y: 30},
                        color: '#FF0000',
                        value: (new Date()).getTime(),
                        width: 2
                    }]
                },
                yAxis: [
                    {// Primary yAxis
                        labels: {
                            formatter: function () {
                                return this.value;
                            },
                            style: {
                                color: '000'
                            }
                        },
                        title: {
                            text: 'Rainfall (mm)',
                            style: {
                                color: '000',
                                fontWeight: 'bold'
                            }
                        },
                        opposite: true

                    },
                    {// Secondary yAxis
                        gridLineWidth: 0,
                        title: {
                            text: 'Rolling Average Temperature (°C)',
                            style: {
                                color: '000',
                                fontWeight: 'bold'

                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value;
                            },
                            style: {
                                color: '000'
                            }
                        },
                        plotLines: [
                            {
                                color: 'red',
                                dashStyle: 'longdashdot',
                                value: 0,
                                width: 2,
                                label: {text: 'Critical Temperature Threshold'}
                            }
                        ],
                        minRange: 0
                    },
                ],
                series: [
                    {
                        name: 'Rolling Average Temperature (°C)',
                        color: '#89A54E',
                        type: 'spline',
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: ' Deg C',
                            valueDecimals: 2
                        }

                    },
                    {
                        name: 'Rainfall (mm)',
                        color: '#4572A7',
                        type: 'column',
                        data: [],
                        tooltip: {
                            valueSuffix: ' mm',
                            valueDecimals: 2
                        }
                    }
                ]

            }
        }
    }]
);