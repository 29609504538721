angular.module("KisanHubDashboardApp").factory("UserSettingsFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/profiles/user-settings/', {}, {
        get: {
            method: 'GET'
        },
        update: {
            method: 'POST'
        }
    });
}]);
angular.module("KisanHubDashboardApp").factory("FieldNamesFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/fms/farmfield/', {},{
            query: {
                method: 'GET',
                params: {as_dict: 'yes'},
                isArray: true
            },
            get: {
                params: {farm_slug: '@field_slug'},
                url: 'api/v1.0/fms/farmfield/:field_slug/',
                method: 'GET'
            }
        });
}]);