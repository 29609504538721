(function (angular) {
    'use strict';
    angular.module('PestDiseaseApp')
        .controller('WheatFusariumOutputController',
            function WheatFusariumOutputController($rootScope,
                                                   $scope,
                                                   $filter,
                                                   $khMessage,
                                                   $mdDialog,
                                                   FusariumOutputFactory,
                                                   monitor_output,
                                                   model_is_active,
                                                   DateTimeHelperFactory,
                                                   DSSUtilsService, GenericUtilsService) {

                var user_can_demo = $rootScope.is_superuser || $rootScope.is_site_admin || $rootScope.is_dss_admin;
                $scope.allowRun = model_is_active || user_can_demo;

                (function getFusariumChart() {
                    FusariumOutputFactory.get({model_run_slug: monitor_output.model_run_slug}, function (response) {
                        $scope.fusariumCharts = response.data.chart_data;
                        setRecommendation(response.data.recommendation);
                        if ($scope.fusariumCharts) pushChartCard();
                    }, function (error) {
                        GenericUtilsService.handleErrorV2($filter, $khMessage, error);
                    });
                })();

                function setRecommendation(recommedation) {
                    $scope.recommendation = recommedation;
                    for (var key in $scope.recommendation) {
                        if ($scope.recommendation.hasOwnProperty(key)) {
                            $scope.recommendation[key] = $scope.recommendation[key].split('\n');
                        }
                    }

                }

                var initiateFusariumOutput = function (monitor_output) {
                    $scope.monitorOutput = monitor_output;
                    $scope.risk = monitor_output.risk;
                };

                initiateFusariumOutput(monitor_output);

                $scope.fusariumCards = [
                    {
                        card_title: $filter('translate')('cropPestDisease.risk_summary'),
                        kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/partials/fusarium/riskSummary.html',
                        kh_card_toolbar_filters_template: '/static/assets/khapps/dss/dss_pestdisease/partials/fusarium/FusariumRiskToolbar.html',
                        card_flex: 100
                    },
                    {
                        card_title: $filter('translate')('cropPestDisease.recommendation'),
                        kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/partials/includes/recommendation.html',
                        card_flex: 100
                    }
                ];

                $scope.reRunModel = function () {
                    $mdDialog.show({
                        templateUrl: '/static/assets/khapps/dss/dss_pestdisease/partials/monitor_input_form_dialog.html',
                        controller: 'RerunFusariumController',
                        skipHide: true,
                        multiple: true,
                        locals: {
                            monitor_output: $scope.monitorOutput,
                            monitorInput: {
                                object_slug: $scope.monitorOutput.location_slug,
                                growing_season: $scope.monitorOutput.growing_season,
                                object_type: $scope.monitorOutput.location_type === 'Farm' ? 'Farm' : 'FieldPlotCrop',
                                PREVIOUS_CROP: $scope.monitorOutput.previous_crop,
                                CULTIVATION_METHOD: $scope.monitorOutput.cultivation_method,
                                DATE_OF_EAR_EMERGENCE: $scope.monitorOutput.date_of_ear_emergence ? moment($scope.monitorOutput.date_of_ear_emergence, 'YYYY-MM-DD').toDate() : new Date()
                            }
                        }
                    }).then(function (response) {
                        response.data.table_data = DSSUtilsService.convertFusariumDatesIntoReadable(DateTimeHelperFactory, response.data.table_data);
                        initiateFusariumOutput(response.data.table_data);
                        $scope.fusariumCharts = response.data.chart_data;
                        setRecommendation(response.data.recommendation);
                        if ($scope.fusariumCharts) pushChartCard();
                        $rootScope.$broadcast('updatedFusariumModelRun', response.data.table_data);
                    });
                };

                function pushChartCard() {
                    if (($scope.fusariumCards.length === 2) && (Object.keys($scope.fusariumCharts).length > 0)) {
                        $scope.fusariumCards.push({
                            card_title: $filter('translate')('cropPestDisease.fusarium_ear_blight_risk'),
                            kh_card_content_template: '/static/assets/khapps/dss/dss_pestdisease/partials/fusarium/riskCharts.html',
                            card_flex: 100
                        });
                    } else if (($scope.fusariumCards.length === 3) && (Object.keys($scope.fusariumCharts).length === 0)) {
                        $scope.fusariumCards.splice(-1);
                    }
                }
            });
})(angular);
